import GoToTop from "../../components/GoToTop/GoToTop";
import UsabilityTesting from "../../assets/images/AAIFF_usability_testing.png";
import CompetitiveAnalysis from "../../assets/images/AAIFF_competitive_analysis.png";
import CurrentWebsite from "../../assets/images/AAIFF_current_website.png";
import UserFlow from "../../assets/images/AAIFF_user_flow.jpg";
import Plan from "../../assets/images/AAIFF_plan.png";
import DesignProposal from "../../assets/images/AAIFF_design_proposal.png";
import MultiDevice from "../../assets/images/Device_Mashups.png";
import MobileThumbnail from "../../assets/images/AAIFF_high_fidelity_wireframes.png";
import MainThumbnail from "../../assets/images/AAIFF_main_thumbnail.png";
import Video from "../../assets/videos/AAIFF_Mobile_Prototype.mov";
import Impact from "../../assets/images/AAIFF_impact.png";
import WebTeam from "../../assets/images/AAIFF_web_team,.jpg";

let AAIFF = () => {
  return (
    <main className="main-container">
      <section className="main projects">
        <span className="feature__title">
          <h1 className="feature__title__text">ASIAN CINEVISION</h1>
          <div className="feature__title__line" />
        </span>{" "}
        <img src={MainThumbnail} className="projects__hero" alt="hero" />
        <a
          href="https://www.aaiff.org/program"
          target="_blank"
          rel="noreferrer"
          className="button-anchor"
        >
          <button className="button button-w-link">View website </button>
        </a>
        <h5 className="projects__subheader">Project overview</h5>
        <article className="keki-client">
          <h4 className="keki-client__title">PRODUCT</h4>
          <p className="keki-category__text">
            The Asian American International Film Festival is the nation's first
            and longest-running festival of its kind established in 1978 by
            Asian Cinevision. It runs a week-long every summer in NYC. I joined
            the web team to help improve the user experience and contributed to
            both UX design and web development.
          </p>
        </article>
        <article className="keki-statement">
          <div className="keki-statement__subcat">
            <h4 className="keki-statement__subcat__title">PROBLEM</h4>
            <p className="keki-statement__subcat__text">
              Users find it confusing to purchase tickets online, leading to
              wrong tickets being bought, high numbers of customer emails, and
              revenue loss for the organization.
            </p>
            <p className="keki-statement__subcat__text">
              Based on email data, our assumptions are that this is due to{" "}
              <strong style={{ backgroundColor: "#dbefbc" }}>
                unclear information and cluttered interface
              </strong>{" "}
              of the current website. The ticketing page contains language that
              is not clear to everyone, leading to{" "}
              <strong style={{ backgroundColor: "#dbefbc" }}>
                poor readability
              </strong>
              . The FAQs page is too overwhelming, making it{" "}
              <strong style={{ backgroundColor: "#dbefbc" }}>
                hard to navigate
              </strong>
              . In addition, Elevent, the third-party ticketing system, provides
              limited customization, preventing a smooth user flow.
            </p>
          </div>
          <div className="keki-statement__subcat">
            <h4 className="keki-statement__subcat__title">GOAL</h4>
            <p className="keki-statement__subcat__text">
              The main objective is to{" "}
              <strong style={{ backgroundColor: "#dbefbc" }}>
                make the ticketing page more user-friendly
              </strong>{" "}
              by providing clear information about the different types of
              tickets and what each of the passes include. Aditionally, creating{" "}
              <strong style={{ backgroundColor: "#dbefbc" }}>
                a simple ticket purchasing flow
              </strong>{" "}
              with instructions would allow the user to seamlessly complete
              their task.
            </p>
            <p className="keki-statement__subcat__text">
              The success of this project will be measured by the following{" "}
              <strong style={{ backgroundColor: "#dbefbc" }}>KPIs</strong>:
            </p>
            <ul className="keki-list">
              <li>Increased ticket sales revenue</li>
              <li>Reduced number of customer support emails</li>
            </ul>
          </div>
        </article>
        <article className="keki-details">
          <div className="keki-details__subcat__team">
            <h4 className="keki-details__subcat__title">TEAM</h4>
            <p className="keki-details__subcat__text">
              My role was UX designer from conception to delivery in
              collaboration with fellow UX designer Lauren Chun. Our team
              included a UX lead, web developers, and a project manager.
            </p>
          </div>
          <div className="keki-details__subcat">
            <h4 className="keki-details__subcat__title">TOOLS</h4>
            <ul className="keki-list">
              <li>Figma</li>
              <li>Google Analytics</li>
              <li>Google Suite</li>
            </ul>
          </div>
          <div className="keki-details__subcat">
            <h4 className="keki-details__subcat__title">
              PROJECT
              <br />
              DURATION
            </h4>
            <p>April 2024 - June 2024</p>
          </div>
        </article>
        <article className="keki-impact">
          <h4 className="keki-research__title">IMPACT</h4>
          <img
            src={Impact}
            className="keki-category__image"
            alt="impact metrics"
          ></img>
        </article>
        <span className="spacer"></span>
        <h5 className="projects__subheader">
          What does our average user want?
        </h5>
        <article className="keki-research">
          <h4 className="keki-research__title">
            Validating our assumptions against real user behavior
          </h4>
          <div className="keki-research__content">
            <p className="keki-research__summary">
              Based on the information provided by the stakeholders and after
              evaluating the ticket purchasing flow, we decided to conduct a
              round of{" "}
              <strong style={{ backgroundColor: "#dbefbc" }}>
                usability testing of the current website
              </strong>{" "}
              that would give us a better understanding of the specific user
              pain points. <br />
              <br />
              The objective of this research phase was to validate our initial
              assumptions as explained under the project goal.
            </p>
            <div className="keki-research__summary">
              <p>These are the subjects we focused on during testing:</p>
              <ul className="keki-list">
                <li>
                  User understanding of the ticketing flow and their behavior
                  when deciding which ticket to buy
                </li>
                <li>Ticket types and pricing confusion</li>
                <li>
                  Usability and navigation of the current interface – if it
                  effectively communicates information about ticket options and
                  event details
                </li>
                <li>Content readability</li>
                <li>Mobile responsiveness</li>
              </ul>
            </div>
          </div>
          <h4 className="keki-research__title">
            When Usability Testing Findings Redefine the Roadmap
          </h4>
          <p className="keki-research__summary">
            The results confirmed our initial assumptions, but most importantly,
            brought to light pain points we hadn't anticipated, increasing the
            scope of our project and urging us to reconsider our original plan.
            These{" "}
            <strong style={{ backgroundColor: "#dbefbc" }}>
              issues involved the main festival program page and the individual
              event pages,
            </strong>{" "}
            which were still part of the ticket purchasing user flow. Below are
            detailed notes on the findings.
          </p>
          <img
            src={UsabilityTesting}
            className="keki-research__image"
            alt="usability testing"
          />
          <h4 className="keki-research__title">What did our users say?</h4>
          <img
            src={CurrentWebsite}
            className="keki-research__image"
            alt="usability testing"
          />
          <h4 className="keki-research__title">
            Benchmarking Success from Leading Film Festivals
          </h4>
          <p className="keki-research__analysis">
            While working on the usability testing, we conducted a competitive
            analysis of other film events in the market to further inform our
            process. After some initial research, we chose to focus on the most
            popular ones. It was helpful to learn how they each package and
            describe their tickets and passes and how they display event data.
          </p>
          <img
            src={CompetitiveAnalysis}
            className="keki-research__image"
            alt="Competitive analysis"
          />
          <h4 className="keki-research__title">
            Redefining the Problem Based on User Struggles
          </h4>
          <div className="keki-plan">
            <p className="keki-plan__text">
              Our research revealed two key challenges:
              <ul className="keki-list">
                <li>
                  Users struggle to identify which ticket type suits their needs
                  and encounter obstacles in completing the purchase flow
                </li>
                <li>
                  Users have difficulty discovering and understanding the value
                  of different festival events
                </li>
              </ul>
              To address these challenges, we needed to simplify the user
              journey and ensure clear, relevant information is provided at each
              decision point.
            </p>
            <p className="keki-plan__text">
              <strong style={{ backgroundColor: "#dbefbc" }}>
                How might we create an intuitive event discovery and ticket
                purchasing experience that guides festival-goers with easily
                accessible information?
              </strong>
              <br /> <br />
              <br />
              We scoped out the project by building a priority matrix in order
              to meet our tight deadline. From here on, Lauren focused on
              desiging the Ticketing and FAQs pages and navigation bar, while I
              focused on the Festival Program and Event Detail pages.
            </p>
          </div>
          <img
            src={Plan}
            className="keki-category__image"
            alt="pain point and opportunities"
          ></img>
        </article>
        <h5 className="projects__subheader">
          Turning findings into design solutions
        </h5>
        <article className="keki-category">
          {/* <article className="keki-category"> */}
          <h4 className="keki-category__title">
            Single event ticket or full access pass?
          </h4>
          <p className="keki-category__text">
            Guided by our research insights, we mapped out a simple user flow
            that would drive user conversion. The solution featured two key
            components: an intuitive filtering system that simplified event
            discovery and a ticketing widget that clearly presented all purchase
            options, strategically highlighting the value of full festival
            access to encourage pass purchases over single tickets.
          </p>
          <img
            src={UserFlow}
            className="keki-category__image__padding"
            alt="user flow"
          ></img>
          {/* </article> */}
          <h4 className="keki-category__title">
            How did we address user pain points through design?
          </h4>
          <img
            src={DesignProposal}
            className="keki-category__image"
            alt="pain point and opportunities"
          ></img>
        </article>
        <h4 className="keki-category__title">
          Iterative Improvements and Responsive Implementation
        </h4>
        <p className="keki-category__text">
          Through multiple review sessions and stakeholder feedback, I refined
          the designs to accommodate various event edge cases and finalized the
          responsive layouts. Throughout this process, I maintained consistent
          alignment with the organization's established design system to ensure
          visual cohesion across the platform.
        </p>
        <img
          src={MobileThumbnail}
          className="keki-category__image"
          alt="hi fi proto"
        ></img>
        <img
          src={MultiDevice}
          className="keki-category__image"
          alt="mockups"
        ></img>
        <span className="keki-movie">
          <h4 className="keki-category__title">FINAL PROTOTYPE</h4>
          <video
            loop
            autoPlay
            muted
            src={Video}
            type="video/mp4"
            className="keki-movie__clip"
          ></video>
        </span>
        <h5 className="projects__subheader">Looking back and moving forward</h5>
        <article className="keki-forward-aaiff">
          <div className="keki-client">
            <h4 className="keki-client__title">CHALLENGES</h4>
            <ul className="keki-list">
              <li>
                <strong style={{ backgroundColor: "#dbefbc" }}>
                  Complex User Research:
                </strong>{" "}
                Multiple ticketing options and insufficient documentation made
                it difficult to comprehensively understand user pain points at
                the beginning stage.
              </li>
              <li>
                {" "}
                <strong style={{ backgroundColor: "#dbefbc" }}>
                  Third-Party Integration Constraints:
                </strong>{" "}
                The Elevent ticketing platform offered limited customization
                capabilities, which restricted our design solution.{" "}
              </li>
              <li>
                <strong style={{ backgroundColor: "#dbefbc" }}>
                  Prioritizing Pain Points:
                </strong>{" "}
                When research revealed additional user problems, we faced
                difficult decisions about which ones to prioritize within
                project constraints.{" "}
              </li>
            </ul>
          </div>
          <div className="keki-client">
            <h4 className="keki-client__title">WHAT I LEARNED</h4>
            <ul className="keki-list">
              <li>
                <strong style={{ backgroundColor: "#dbefbc" }}>
                  Prioritize features that address the most pressing user pain
                  points.
                </strong>{" "}
                By analyzing data from user testing, we focused on changes that
                would immediately improve event discovery and ticket purchase
                journey, leading to more intuitive navigation and clearer event
                information.
              </li>
              <li>
                <strong style={{ backgroundColor: "#dbefbc" }}>
                  Advocate for user-centered solutions while addressing key
                  business objectives
                </strong>{" "}
                — such as increasing ticket sales. This balance improved the
                user experience and helped us make a stronger case for future
                design investments.
              </li>
            </ul>
          </div>
          <div className="keki-client">
            <h4 className="keki-client__title">IF I HAD MORE TIME</h4>
            <ul className="keki-list">
              <li>
                Conduct a round of{" "}
                <strong style={{ backgroundColor: "#dbefbc" }}>
                  usability testing on the updated design
                </strong>{" "}
                with a more representative sample of target users to validate
                that our solution effectively addresses the established pain
                points.
              </li>
              <li>
                <strong style={{ backgroundColor: "#dbefbc" }}>
                  Analyze customer service communications
                </strong>{" "}
                from this year's festival to identify recurring patterns and
                emerging user pain points that could inform future iterations.
              </li>
              <li>
                <strong style={{ backgroundColor: "#dbefbc" }}>
                  Implement a customer satisfaction survey
                </strong>{" "}
                to evaluate user experiences and identify opportunities for
                improving the website.
              </li>
            </ul>
          </div>
        </article>
        <span className="web-team">
          <img
            src={WebTeam}
            className="web-team__image"
            alt="Web team members"
          />
          <p className="web-team__caption">
            The web team at AAIFF's Opening Party 🎉
          </p>
        </span>
      </section>
      <GoToTop />
    </main>
  );
};

export default AAIFF;
